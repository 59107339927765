import React from "react";
import { useTimestamp } from "./util";
import Countdown from "./components/countdown";

function App() {
  let timestamp = useTimestamp();
  let [showDetails, setShowDetails] = React.useState(false);
  let onClick = () => setShowDetails(!showDetails);
  return (
    <div className="flex h-screen justify-center items-center ">
      <header>
        <p className="text-3xl text-neutral-50 "> 𝔸𝕝𝕖𝕥𝕥𝕒 畢業</p>
        <Countdown showDetails={showDetails} timestamp={timestamp}></Countdown>
        <button
          className="text-1xl text-gray mt-2 bg-white rounded-full  w-full"
          onClick={onClick}
        >
          {"詳細時間"}
        </button>
        <a
          href="https://www.instagram.com/yukimura.sora__"
          target="_blank"
          rel="noreferrer noopener"
        >
          <button className="text-1xl text-gray mt-2 bg-white rounded-full  w-full">
            {"艾萊塔的 Insta"}
          </button>
        </a>
      </header>
    </div>
  );
}

export default App;
