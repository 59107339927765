const lastDayDetail = 1713621600;
const lastDay = 1713542400;

interface CountdownProps {
  showDetails: boolean;
  timestamp: number;
}

export default function Countdown({ showDetails, timestamp }: CountdownProps) {
  let diff = timestamp - (showDetails ? lastDayDetail : lastDay);
  let days = Math.floor(diff / 86400);
  let hours = Math.floor((diff % 86400) / 3600);
  let minutes = Math.floor((diff % 3600) / 60);
  let seconds = diff % 60;
  return (
    <div>
      <div>
        <h1 className="text-3xl text-neutral-50 center">
          {showDetails ? days : days} 天{showDetails ? "" : "前"}
        </h1>
      </div>
      {showDetails && (
        <div>
          <div>
            <p className="text-neutral-50">
              {hours} 小時 {minutes} 分鐘 {seconds} 秒前
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
