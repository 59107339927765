import { useState, useEffect } from "react";

export const useTimestamp = () => {
  const [timestamp, setTimestamp] = useState(Math.round(Date.now() / 1000));
  useEffect(() => {
    const timer = setInterval(() => {
      setTimestamp(Math.round(Date.now() / 1000));
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  return timestamp;
};
